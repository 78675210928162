// app

(function ($) {

	'use strict';

	window.App = {

		// Define the selectors
		window: $(window),
		body: $('body'),
		html: $('html'),
		page: $('.page'),
		accordionSelector: '.accordion',
		accordionTitleLinkSelector: '.accordion .header a',
		hasDropDownSelector: '.has-dropdown',
		mainNavigationSelector: '.main-navigation',
		mobileMenuSelector: '.mobile-menu',
		sliderSelector: '.swiper-container',
		subNavigationSelector: '.sub-navigation',
		toggleMobileSearchSelector: '.toggle-mobile-search',
		fixedHeaderClass: 'fixed-header',
		subNavigationLoaded: false,

		/**
		 * Initialise the object
		 */
		init: function () {

			console.log('app init');

			var self = this;
      
			// on mobile header fixed top position
			this.mobileHeaderFixed();
			this.scrollToElement();

			// Initialize mobile menu
			var mobileMenu = $(this.mobileMenuSelector);
			mobileMenu.mmenu({
				extensions: ["multiline"]
			});
			var mobileMenuAPI = mobileMenu.data( "mmenu" );
			mobileMenuAPI.bind('opened', function () {
				if (!$('.close-m').length) {
					$('.mm-navbar').append('<div class="close-m" aria-controls="menu" aria-expanded="true">sluiten</div>');
				} else {
					$('.close-m').attr('aria-expanded', true);
				}
				$('.close-m').on('click', function() {
					mobileMenuAPI.close();
					$('.close-m').attr('aria-expanded', false);
				});
			});

			// Initialize accordion
			$(this.accordionSelector).accordion({
				'transitionSpeed': 300,
				'singleOpen': false
			});

			// Initialize slider
			$(this.sliderSelector).each(function () {
				new self.Slider($(this));
			});

			// eventhandler phone-label

			$(".call-tab").keypress(function (event) {
				event.preventDefault();
				if (event.keyCode === 13) { //wehn pressed enter
					$('.phone-label').click();
				}
			});
			$('.phone-label').on('click', function () {
				$(this).toggleClass("open");
			});

			// Initialize popups
			this.Popup.init();

			this.bind();

		},

		/**
		 * Set up event handlers
		 */
		bind: function () {

			var self = this;

			// Initialize mobile search toggle
			$(this.toggleMobileSearchSelector).on('click', function (event) {
				console.log('toggleMobileSearchSelector');
				self.html.toggleClass('show-search');
				event.preventDefault();
			});

			// Initialize fixed header
			this.window.scroll(function () {
				if (self.window.scrollTop() > $(self.mainNavigationSelector).height()) {
					self.html.addClass(self.fixedHeaderClass);
				} else {
					self.html.removeClass(self.fixedHeaderClass);
				}
			});

			// Initialize sub navigation
			if ($(this.subNavigationSelector).length) {
				this.window.on('resize', function () {
					self.updateSubNavigation()
				});
			}

			// Initialize drop downs
			$(this.hasDropDownSelector).each(function () {

				var el = $(this),
					link = el.find('> a'),
					href = link.attr('href'),
					touchEnabled = false;

				el.on('focusin', function () {
					el.addClass('open');
				}).on('focusout mouseout', function () {
					el.removeClass('open');
					touchEnabled = false;
				});

				link.on('touchstart', function () {
					if (touchEnabled) {
						window.location = href;
					} else {
						touchEnabled = true;
					}
				}).on('click', function (event) {
					if (touchEnabled) {
						event.preventDefault();
					}
				});
			});

			$(this.accordionSelector).on('click', function (e) {
				var target = $(e.target),
					pageOffset = $(document).scrollTop(),
					inside = $(e.target).closest('.content').length;
				if (!inside) {
					setTimeout(function () {
						var offsetTarget = target.offset().top;
						$('html, body').animate({
							scrollTop: (offsetTarget - 80)
						}, 300);
					}, 350);
				}
			});

			// Prevents following the header link of an accordion
			$(this.accordionTitleLinkSelector).on('click', function (event) {
				event.preventDefault();
			});

			// Prevents following the more link in the sub navigation
			$(this.subNavigationSelector).find('.more').on('click', function (event) {
				if (!($(event.target).closest('.drop-down').length)) {
					event.preventDefault();
				}
			});
		},

		scrollToElement() {
			let context = this;
			$('a[href^="#"]').on('click', function(e) {
				if ($(this).attr('href') !== '#' || $(this).attr('href').length > 1) {
					e.preventDefault();
					var ismobile = $(window).width() < 841 ? true : false;
					var navbarheight = 0;
					if (ismobile) {
						navbarheight = $('#PageHeader').outerHeight();
					}
					var scrollTop = $($(this).attr('href')).offset().top - navbarheight;
					if ($('.abc-overview').hasClass('afdeling')) {
						scrollTop = $($(this).attr('href')).offset().top - navbarheight - $($(this).attr('href')).outerHeight() - 20;
					}
					$('html, body').animate({ scrollTop }, 1000);
				}
			});
		  },

		/**
		 * Initialize sub navigation
		 */
		updateSubNavigation: function () {

			$(this.subNavigationSelector).children().each(function () {

				var nav = $(this).find('nav'),
					navWidth = nav.width(),
					list = nav.find('> ul'),
					more = nav.find('.more'),
					moreWidth = Math.round(more.outerWidth(true)) + 20,
					availableWidth = 0,
					showMoreButton = false;

				list.children('li').not(more).each(function (index) {
					var el = $(this);
					availableWidth += Math.round(el.outerWidth(true));
					if (availableWidth < (navWidth - moreWidth)) {
						el.show();
						more.find('li').eq(index).hide();
					} else {
						showMoreButton = true;
						el.hide();
						more.find('li').eq(index).show();
					}
				});
				showMoreButton ? more.addClass('show') : more.removeClass('show');
			});

			if (!this.subNavigationLoaded) {
				$(this.subNavigationSelector).find('nav').addClass('loaded');
				this.subNavigationLoaded = true;
			}

			//test
		},

		Custompopup() {
			var popup = $('.popup_holder');
			if (popup.length) {
				var visible = popup.css('display');
				if (visible == 'block') {
					console.log('add class no-scroll');
					$('html').addClass('no-scroll');
				} else {
					console.log('remove class no-scroll');
					$('html').removeClass('no-scroll');
				}
				popup.on('click', function (e) {
					e.preventDefault();
					console.log('on click .popup_holder');
					var visible = popup.css('display');
					if (visible == 'none') {
						$('html').removeClass('no-scroll');
					}
				});
			}
		},
		mobileHeaderFixed() {
			var $pageheader = $('#PageHeader');
			$(window).scroll(function() {
				var scroll = $(window).scrollTop();
				if ($(window).width() < 1024 && (scroll > triggerFixedMenuHeight() + $pageheader.innerHeight())) {
					$pageheader.addClass('fixed-header');
				} else {
					$pageheader.removeClass('fixed-header');
				}
			});
			
			function triggerFixedMenuHeight() {
				let height = 0;
				$('.calamiteit-container').each(function(index, element) {
					height += $(element).innerHeight();
				});
				if ($('.omgeving').length) {
					height += $('.omgeving').innerHeight();
				}
				return height;
			}
		}
	};

}(jQuery));

// app.popup

(function ($) {

	'use strict';

	/**
	 * Popup object
	 */

	App.Popup = {

		gallerySelector: '.gallery-popup',
		imageSelector: '.image-popup',
		imageGallerySelector: '.image-popup-gallery',
		videoSelector: '.video-popup',
		nextLabel: 'Volgende',
		previousLabel: 'Vorige',

		imagePopupOptions: {
			closeMarkup: '<a title="%title%" class="mfp-close"><span>Sluiten</span></a>',
			image: {
				titleSrc: function titleSrc(item) {
					return item.el.find('img').attr('alt');
				}
			},
			type: 'image',
			zoom: {
				easing: 'ease-in-out',
				enabled: true,
				duration: 200
			}
		},

		videoPopupOptions: {
			closeMarkup: '<a title="%title%" class="mfp-close"><span>Sluiten</span></a>',
			disableOn: 767,
			type: 'iframe',
			zoom: {
				easing: 'ease-in-out',
				enabled: true,
				duration: 20000
			}
		},

		/**
		 * Initialise the object
		 */
		init: function init() {
			this.bind();
		},

		/**
		 * Set up event handlers
		 */
		bind: function bind() {

			var self = this;
			//var messageIsConfirmed = false;

			// Image popup
			$(this.imageSelector).magnificPopup(this.imagePopupOptions);

			// Gallery popup
			$(this.gallerySelector).each(function () {
				$(this).magnificPopup($.extend({
					delegate: self.imageGallerySelector,
					gallery: {
						arrowMarkup: '<button title="%title%" type="button" class="dd mfp-arrow mfp-arrow-%dir%"></button>',
						enabled: true,
						tCounter: '<span class="mfp-counter">%curr% / %total%</span>',
						tPrev: self.previousLabel,
						tNext: self.nextLabel
					}
				}, self.imagePopupOptions));
			});

			// Video popup
			if (typeof futCookieConsent != "undefined" && futCookieConsent.cookieAccepted()) {
				$(this.videoSelector).magnificPopup(this.videoPopupOptions);
			} else {
				$(this.videoSelector).on('click', function (e) {
					e.preventDefault();
					var message = 'U kunt deze actie pas uitvoeren indien u cookies accepteert.';
					alert(message);
				});
			}
		}
	};
})(jQuery);

(function () {

	'use strict';

	App.Slider = function (container, options) {
		this.opts = $.extend(this.defaults, options);
		this.container = container;
		this.slides = this.container.find(this.opts.slideSelector);

		if (this.slides.length > 1) {
			this.initSlider();
		}
	};

	App.Slider.prototype.defaults = {
		autoplay: 6000,
		effect: 'fade',
		nextButton: '.swiper-button-next',
		pagination: '.swiper-pagination',
		paginationClickable: true,
		prevButton: '.swiper-button-prev',
		slideSelector: '.swiper-slide',
		spaceBetween: 0,
		speed: 400
	};

	App.Slider.prototype.initSlider = function () {
		new Swiper(this.container, this.opts);
	};
})();

$(document).ready(function () {
	App.init();
	App.Popup.init();
	$(window).trigger('resize');
});